import React, { createContext, useMemo, useState } from "react";
import { ContextDevTool } from "react-context-devtool";

import { CitiesProviderProps as Props } from "./cities.context.types";
import { CitiesProviderValue } from "./cities.context.types";
import { City } from "services/orders/orders.service.types";
import CONSTANTS from "config/constants";

const { DEFAULT_CITY } = CONSTANTS.ARTISN;

// @ts-ignore
export const CitiesContext = createContext<CitiesProviderValue>();

const CitiesProvider: React.FC<Props> = props => {
  const [selectedCity, setSelectedCity] = useState<City>(DEFAULT_CITY);

  const value: CitiesProviderValue = useMemo(() => {
    return { selectedCity, setSelectedCity };
  }, [selectedCity]);

  return (
    <CitiesContext.Provider value={value}>
      <ContextDevTool
        context={CitiesContext}
        id="cities"
        displayName="Cities"
      />
      {props.children}
    </CitiesContext.Provider>
  );
};

export default CitiesProvider;
