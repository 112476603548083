// Geo utility functions
import { Geolocation, Position } from "@capacitor/geolocation";
import { Store } from "artisn/types";

import { StorePolygonCoords } from "types/geo.types";
import { notify } from "./common.utils";

export const getCurrentPosition = async (
  onSuccess: (position: Position) => void,
  onError?: (error: Error) => void
) => {
  try {
    const permissions = await Geolocation.checkPermissions();
    if (permissions.location === "denied") {
      const requested = await Geolocation.requestPermissions();
      if (requested.location !== "denied") {
        const position = await getPosition();
        return onSuccess(position);
      }
      onError?.({
        name: "LocationError",
        message: "Location permissions were not given"
      });
    }
    return onSuccess(await getPosition());
  } catch (e) {
    notify(e, "Get current position");
    return onError?.(e);
  }
};

export const getPosition = async () => {
  return await Geolocation.getCurrentPosition();
};

export const getCoverageAreasFromStores = (stores: Store[] | undefined) => {
  if (!stores) return [];

  const globalPolygons: StorePolygonCoords = {};

  for (const store of stores) {
    const { storeId, polygons } = store;
    const polygonsCoord = polygons?.coordinates;
    globalPolygons[storeId] = [];
    if (!polygonsCoord) continue;
    for (const polygonCoord of polygonsCoord) {
      const polygonCoordsArray = polygonCoord.flat();
      const polygon = [];
      for (const coords of polygonCoordsArray) {
        const [lat, lng] = coords;
        polygon.push({ lat, lng });
      }
      globalPolygons[storeId].push(polygon);
    }
  }

  return globalPolygons;
};
