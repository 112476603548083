import { createApp } from "artisn/init";
import { Providers } from "@artisan-commerce/analytics-capacitor";
import { initAuth, getServices } from "artisn/auth";
import { Capacitor } from "@capacitor/core";

import CONSTANTS from "config/constants";
import { FACEBOOK_PIXEL_WEB_ID } from "config/constants";

const { ARTISN, GENERAL, API } = CONSTANTS;
const { PLATFORM } = GENERAL;
const { ACCOUNT_ID } = ARTISN;
const { API_URL } = API;

const isIOS = Capacitor.getPlatform() === "ios";
const isAndroid = Capacitor.getPlatform() === "android";
const isMobile = isIOS || isAndroid;

export const getENVs = (() => {
  const env = process.env.NEXT_PUBLIC_ENV ?? "develop";

  return {
    develop: {
      apiKey: "AIzaSyDHRnuyf8IEB1at438jz89t9mkDaXiya-8",
      authDomain: "mosumi-prod.firebaseapp.com",
      databaseURL: "https://mosumi-prod-default-rtdb.firebaseio.com",
      projectId: "mosumi-prod",
      storageBucket: "mosumi-prod.appspot.com",
      messagingSenderId: "1014187396077",
      appId: "1:1014187396077:web:8a69a176fa03d54aa2b5e4",
      measurementId: "G-RQWQHD7T3E",
      mapsApiKey: "AIzaSyDpMo-VC9x37HWCNxbJ-jw65wKoqua56jg",
      facebookAppId: "371136045167580"
    },
    production: {
      apiKey: "AIzaSyDHRnuyf8IEB1at438jz89t9mkDaXiya-8",
      authDomain: "mosumi-prod.firebaseapp.com",
      databaseURL: "https://mosumi-prod-default-rtdb.firebaseio.com",
      projectId: "mosumi-prod",
      storageBucket: "mosumi-prod.appspot.com",
      messagingSenderId: "1014187396077",
      appId: "1:1014187396077:web:8a69a176fa03d54aa2b5e4",
      measurementId: "G-RQWQHD7T3E",
      mapsApiKey: "AIzaSyDpMo-VC9x37HWCNxbJ-jw65wKoqua56jg",
      facebookAppId: "371136045167580"
    }
  }[env];
})();

export const artisn = createApp(
  {
    projectId: getENVs?.projectId ?? "",
    apiKey: getENVs?.apiKey ?? "",
    authDomain: `${getENVs?.projectId}.firebaseapp.com`,
    accountId: ACCOUNT_ID,
    platform: PLATFORM
  },
  API_URL
);

export const auth = () => initAuth(artisn);
export const Services = getServices(artisn);
export const firestore = artisn?.__internals__!.firestore();

export const facebookProvider = new Providers.FacebookPixel({
  name: "facebook",
  // There might be different values for web and app
  trackingId: isMobile
    ? `${getENVs?.facebookAppId ?? ""}`
    : FACEBOOK_PIXEL_WEB_ID,
  version: "2.0"
});

export const googleProvider = new Providers.GoogleAnalytics({
  name: "google",
  trackingId: getENVs?.measurementId ?? ""
});
