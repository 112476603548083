import React from "react";

import Styles from "./ErrorNotification.styles";
import { ErrorNotificationProps as Props } from "./ErrorNotification.types";

const ErrorNotification: React.FC<Props> = props => {
  const { className, message, title, clickable = false } = props;
  const text = `${title ? title : "Parece que algo falló"}: ${message}`;

  return (
    <Styles
      className={`ErrorNotification ${className}`}
      showCloseIcon={false}
      clickable={clickable}
    >
      <p>{text}</p>
    </Styles>
  );
};

ErrorNotification.defaultProps = {
  className: ""
};

export default ErrorNotification;
