import styled, { css } from "styled-components";
import { Button as ButtonUI } from "artisn-ui-react";

import { ButtonColor, ButtonStyledProps as Props } from "./Button.types";

const mapType = (props: Props) => {
  const { type, color } = props;
  switch (type) {
    case "FILLED":
      return mapColorByFilledType(color);
    case "BORDER":
      return mapColorByBorderType(color);
    case "LINK":
      return mapColorByLinkType(color);
    default:
      return mapColorByFilledType(color);
  }
};

const mapColorByFilledType = (color?: ButtonColor) => {
  switch (color) {
    case "primary":
      return css`
        background-color: var(--palette-red-s85-l45);
        font-weight: 600;

        &:hover {
          &:not([disabled]) {
            border: 0.1rem solid var(--palette-white);
            background-color: var(--palette-white);
            color: var(--palette-red-s85-l45);

            path {
              fill: var(--palette-red-s85-l45);
            }
          }
        }

        &:disabled {
          background-color: var(--palette-gray-s3-l85);
          color: var(--palette-gray-s0-l21);
          box-shadow: none;
        }
      `;

    case "primary-light":
      return css`
        border: 0.1rem solid var(--palette-purple-s100-l99);
        background-color: var(--palette-purple-s100-l99);
        color: var(--palette-red-s85-l45);
        font-weight: 600;

        &:hover {
          &:not([disabled]) {
            border: 0.1rem solid var(--palette-red-s85-l45);
          }
        }

        &:disabled {
          background-color: var(--palette-gray-s3-l85);
          color: var(--palette-gray-s0-l21);
          box-shadow: none;
        }
      `;

    case "black":
      return css`
        border: 0.1rem solid var(--palette-blue-s51-l16);
        background-color: var(--palette-blue-s51-l16);
        color: var(--palette-white);
        font-weight: 600;

        &:hover {
          &:not([disabled]) {
            background-color: var(--palette-blue-s51-l16);
            border: 0.1rem solid var(--palette-blue-s51-l16);
          }
        }

        &:disabled {
          background-color: var(--palette-gray-s3-l85);
          color: var(--palette-gray-s0-l21);
          box-shadow: none;
        }
      `;

    default:
      return css`
        background-color: var(--palette-red-s85-l45);

        &:hover {
          &:not([disabled]) {
            border: 0.1rem solid var(--palette-white);
            background-color: var(--palette-white);
            color: var(--palette-red-s85-l45);

            path {
              fill: var(--palette-red-s85-l45);
            }
          }
        }

        &:disabled {
          background-color: var(--palette-gray-s3-l85);
          color: var(--palette-gray-s0-l21);
          box-shadow: none;
        }
      `;
  }
};

const mapColorByBorderType = (color?: ButtonColor) => {
  switch (color) {
    case "primary":
      return css`
        border: 0.2rem solid var(--palette-red-s85-l45);
        color: var(--palette-red-s85-l45);

        &:hover {
          &:not([disabled]) {
            background-color: var(--palette-red-s85-l45);
            border: 0.2rem solid var(--palette-red-s85-l45);
            color: var(--palette-white);
          }
        }

        &:disabled {
          border: 0.2rem solid var(--palette-gray-s3-l85);
          color: var(--palette-gray-s0-l21);
          box-shadow: none;
        }
      `;

    case "primary-light":
      return css`
        border: 0.2rem solid var(--palette-purple-s100-l99);
        color: var(--palette-primary-dark);

        &:hover {
          &:not([disabled]) {
            color: var(--palette-red-s85-l45);
          }
        }

        &:disabled {
          border: 0.2rem solid var(--palette-purple-s100-l99);
          color: var(--palette-gray-s0-l80);
          box-shadow: none;
        }
      `;

    case "black":
      return css`
        border: 0.2rem solid var(--palette-black);
        color: var(--palette-black);

        &:hover {
          &:not([disabled]) {
            background-color: var(--palette-gray-s0-l20);
            color: var(--palette-white);
            border: 0.2rem solid var(--palette-black);
          }
        }

        &:disabled {
          color: var(--palette-gray-s3-l85);
          box-shadow: none;
        }
      `;

    default:
      return css`
        border: 0.2rem solid var(--palette-red-s85-l45);
        color: var(--palette-red-s85-l45);

        &:hover {
          &:not([disabled]) {
            background-color: var(--palette-red-s85-l45);
            border: 0.2rem solid var(--palette-red-s85-l45);
            color: var(--palette-white);
          }
        }

        &:disabled {
          border: 0.2rem solid var(--palette-gray-s3-l85);
          color: var(--palette-gray-s0-l21);
          box-shadow: none;
        }
      `;
  }
};

const mapColorByLinkType = (color?: ButtonColor) => {
  switch (color) {
    case "primary":
      return css`
        color: var(--palette-red-s85-l45);
        font-weight: 600;

        &:hover {
          &:not([disabled]) {
            color: var(--palette-red-s85-l45);
          }
        }

        &:disabled {
          color: var(--palette-red-s85-l45);
          box-shadow: none;
        }
      `;

    case "black":
      return css`
        color: var(--palette-black);
        font-weight: 600;

        &:hover {
          &:not([disabled]) {
            color: var(--palette-gray-s0-l20);
          }
        }

        &:disabled {
          box-shadow: none;
        }
      `;

    default:
      return css`
        color: var(--palette-red-s85-l45);
        font-weight: 600;

        &:hover {
          &:not([disabled]) {
            color: var(--palette-red-s85-l45);
          }
        }

        &:disabled {
          color: var(--palette-red-s85-l45);
          box-shadow: none;
        }
      `;
  }
};

const ButtonStyled = styled(ButtonUI)<Props>`
  &.Button {
    display: flex;
    box-shadow: none;
    outline: none;
    background-color: none;
    border-radius: 0.4rem;
    cursor: pointer;
    padding: 0.8rem 2.4rem;
    ${mapType}
  }

  .Button {
  }
`;

export default ButtonStyled;
