import { useEffect } from "react";
import { Storage } from "@capacitor/storage";

import CONSTANTS from "config/constants";
import useCheckout from "components/checkout/Checkout/context/checkout/checkout.context.hooks";
import useAuth from "contexts/auth/auth.context.hooks";

const { CHECKOUT_FORM_DATA_TOKEN } = CONSTANTS.STORAGE;

const useListenCheckoutFormData = () => {
  const { formData, setFormData } = useCheckout();
  const { isAnonymous, uid } = useAuth();

  /**
   * Every time the checkout form data changes,
   * save it on local storage.
   */
  useEffect(() => {
    (async () => {
      if (!formData || isAnonymous) return;
      await Storage.set({
        key: `${CHECKOUT_FORM_DATA_TOKEN}${uid}`,
        value: JSON.stringify(formData)
      });
    })();
  }, [formData, isAnonymous, uid]);

  /** Get checkout form data from local storage and set it on context. */
  useEffect(() => {
    setTimeout(() => {
      (async () => {
        if (isAnonymous) return;
        const { value } = await Storage.get({
          key: `${CHECKOUT_FORM_DATA_TOKEN}${uid}`
        });
        const storageFormData = value ? JSON.parse(value) : undefined;
        if (!storageFormData) return;
        setFormData(storageFormData);
      })();
    }, 5000);
  }, [setFormData, uid, isAnonymous]);
};

export default useListenCheckoutFormData;
