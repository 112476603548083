import styled from "styled-components";

import { AddToWishlistNotificationStyledProps as Props } from "./AddToWishlistNotification.types";
import Notification from "components/global/notifications/Notification/Notification";
import CONSTANTS from "config/constants";

const { mobile } = CONSTANTS.BREAKPOINTS;

const AddToWishlistNotificationStyled = styled(Notification)<Props>`
  padding: 1rem 2rem;
  background-color: ${props =>
    props.isAnonymous
      ? "var(--palette-purple-s66-l84);"
      : "var(--palette-green-s74-l85)"};

  @media (max-width: ${mobile}px) {
    padding: 1.8rem 2rem;
  }

  .AddToWishlistNotification {
    &__description {
      font-size: 1.6rem;
      color: var(--palette-black-s0-l10);
      padding-left: 0.8rem;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    &__button {
      font-weight: 600;
      font-size: 1.6rem;
      background-color: var(--palette-white);
      margin-left: 1.6rem;
      border-radius: 10rem;
      padding: 1rem 2rem;
      color: var(--palette-primary);
      min-width: max-content;

      @media (max-width: ${mobile}px) {
        margin-left: 0;
      }
    }
  }
`;

export default AddToWishlistNotificationStyled;
