import React from "react";
import router from "next/router";

import Styles from "./ContinueCheckoutNotification.styles";
import { ContinueCheckoutNotificationProps as Props } from "./ContinueCheckoutNotification.types";
import Button from "components/global/Button/Button";

const ContinueCheckoutNotification: React.FC<Props> = props => {
  return (
    <Styles className="ContinueCheckoutNotification Notification">
      <p className="ContinueCheckoutNotification__description">
        Ya tienes una mascota en proceso de adopción
      </p>
      <Button
        className="ContinueCheckoutNotification__button"
        type="LINK"
        onClick={() => router.push("/checkout")}
      >
        Ver estado
      </Button>
    </Styles>
  );
};

ContinueCheckoutNotification.defaultProps = {};

export default ContinueCheckoutNotification;
