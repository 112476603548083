// Common utility functions

import { Benefit, CartProduct, Product, ShippingAddress } from "artisn/types";
import { CategoryWithProducts } from "artisn/types";
import { DropdownProps } from "artisn-ui-react";
import { camelCase } from "voca";
import Bugsnag from "@bugsnag/js";

import CONSTANTS from "config/constants";
import { CustomScriptAttributes } from "types/common.types";
import { ApiWarning } from "types/api.types";
import { PostShippingAddressPayload } from "services/shippingAddress/shippingAddress.service.types";
import { postPhoto } from "services/images/images.service";

const { BREAKPOINTS, API } = CONSTANTS;
const { MOCK_SERVICES } = API;
const { desktop } = BREAKPOINTS;

export const dropdownConfigs: Partial<DropdownProps> = {
  isFixed: true,
  target: ["focus"]
};

export const sortByDate = (a: string, b: string) => {
  const dateA = new Date(a).getTime();
  const dateB = new Date(b).getTime();

  if (dateA > dateB) {
    return 1;
  } else if (dateA < dateB) {
    return -1;
  } else {
    return 0;
  }
};

export const sortByProductDate = (a: CartProduct, b: CartProduct) => {
  return sortByDate(a.createdAt, b.createdAt);
};

export const joinProductsByCategories = (
  categories: CategoryWithProducts[]
) => {
  return categories.reduce((acc, category) => {
    return [...acc, ...category.products];
  }, [] as Product[]);
};

export const scrollToElement = (elementId: string, isModal?: boolean) => {
  const element = document?.getElementById?.(elementId);
  const navbar = document?.getElementById?.("navbar");
  const modifiers = document?.getElementById?.("modifiers");
  const modalHeader = document?.getElementById("modal-header");
  const navbarHeight = navbar?.clientHeight ?? 0;
  const modalHeaderHeight = modalHeader?.clientHeight ?? 0;
  const offsetTop = (element?.offsetTop ?? 0) - (!isModal ? navbarHeight : 0);
  if (isModal) {
    modifiers?.scrollTo({
      top: offsetTop - modalHeaderHeight,
      behavior: "smooth"
    });
    return;
  }
  window.scrollTo({ top: offsetTop, behavior: "smooth" });
};

export const defaultFunction = () => {};

export const removeQueryParam = (path: string, queryParam: string) => {
  const urlBase = path.split("?")?.[0];
  const queryParams = path.split("?")?.[1];
  if (!queryParams) {
    return urlBase;
  }

  const paramsArray = queryParams.split("&");
  const newQueryParams = paramsArray
    .filter(param => !param.includes(`${queryParam}=`))
    .join("&");

  if (!newQueryParams) {
    return urlBase;
  }

  return `${urlBase}?${newQueryParams}`;
};

export const getFullPath = () => {
  if (typeof window === "undefined") return;

  return window.location.origin + window.location.pathname;
};

export const getDomain = () => {
  if (typeof window === "undefined") return;

  return window.location.origin;
};

export const getMapSize = (
  windowWidth: number,
  windowHeight: number,
  padding = 0
) => {
  let width = windowWidth - padding;
  let height = 160;
  if (windowWidth >= desktop) {
    width = 500;
    height = 180;
  }
  return { width, height };
};

// This value should not be modified if you want to disable mocks
// To disable mocks, go to config/constants.ts
export const shouldMock =
  process.env.NEXT_PUBLIC_ENV === "production" ? false : MOCK_SERVICES;

export const isTouchScreenDevice = () => {
  if (typeof window === "undefined") return;
  return "ontouchstart" in window || navigator.maxTouchPoints;
};

export const encode64 = (string: string) =>
  Buffer.from(string, "binary").toString("base64");

export const getBenefitProductId = (
  temporalBenefit?: Benefit,
  cartBenefit?: Benefit
) => {
  const { type, award } = temporalBenefit ?? cartBenefit ?? {};
  return type === "PRODUCT" && Array.isArray(award)
    ? award[0].productId.toString()
    : "";
};

export const loadScript = (attributes: CustomScriptAttributes) => {
  const { id } = attributes;
  const existingScript = document.getElementById(id);
  if (existingScript) return;
  const script = document.createElement("script");
  Object.entries(attributes).forEach(entry => {
    const [key, value] = entry;
    const realKey = key.toLocaleLowerCase() as keyof CustomScriptAttributes;
    // @ts-ignore The DOM script attributes can't be typed
    script[realKey] = value;
  });
  document.body.appendChild(script);
};

export const removePayPhoneButton = () => {
  const payPhoneButtonContainer = document.getElementById("pp-button");
  while (payPhoneButtonContainer?.firstChild) {
    payPhoneButtonContainer.removeChild(payPhoneButtonContainer.firstChild);
  }
};

export const removePlaceToPayScript = () => {
  const script = document.getElementById("placeToPay");
  script?.remove();
};

export const sanitizeQueryParams = (query: NodeJS.Dict<string | string[]>) => {
  const newQuery: Record<string, string | undefined> = {};
  Object.entries(query).forEach(entry => {
    const [key, value] = entry;
    if (typeof value === "string") newQuery[key] = value;
  });
  return newQuery;
};

export const removeDuplicates = <T>(items: T[]) => {
  return Array.from(new Set(items));
};

export const transformObjectKeysToCamelCase = <T, U>(params: T) => {
  return Object.entries(params).reduce<U>((acc, params) => {
    const [key, value] = params;
    const newKey = camelCase(key as string);
    return {
      ...acc,
      [newKey]: value
    };
  }, {} as U);
};

export const getNameAndLastName = (completeName: string) => {
  const [name, ...rest] = completeName.split(" ");
  return { name, lastName: rest.join(" ") };
};

export const mapAPIErrors = (warnings: ApiWarning[] | undefined): string[] => {
  if (!warnings) return [];
  return warnings.map(warning => warning.value);
};

export const getWarningField = (
  warnings: ApiWarning[] | undefined
): string[] => {
  if (!warnings) return [];
  return warnings.map(warning => warning.field);
};

export const getFirebaseAuthErrorMessage = (code?: string) => {
  switch (code) {
    case "auth/wrong-password":
      return "Los datos son inválidos";
    case "auth/user-not-found":
      return "El correo no está registrado";
    case "auth/too-many-requests":
      return "Demasiadas solicitudes. El acceso a esta cuenta se ha inhabilitado temporalmente";
    case "auth/email-already-in-use":
      return "Esta cuenta ya existe, inicia sesión";
    case "auth/network-request-failed":
      return "Ha ocurrido un error de conexión, intenta nuevamente";
    default:
      return "";
  }
};

export const getPostShippingAddress = (
  selectedShippingAddress: ShippingAddress
) => {
  const { country, lat, lng, livingPlace } = selectedShippingAddress;
  const { mainStreet, nickname, number, reference } = selectedShippingAddress;
  const { numberContactAddress, secondaryStreet } = selectedShippingAddress;
  const { updatedAt, default: defaultAddress } = selectedShippingAddress;
  const postShippingAddressPayload: PostShippingAddressPayload = {
    country,
    lat,
    lng,
    livingPlace,
    mainStreet,
    nickname,
    number,
    numberContactAddress,
    reference,
    secondaryStreet,
    updatedAt,
    default: defaultAddress
  };
  return postShippingAddressPayload;
};

export const getBase64 = (file: File) => {
  return new Promise<string | ArrayBuffer>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      if (reader.result === null) {
        throw new Error("Couldn't transform image");
      }
      const base64Result = reader.result as string;
      const withoutType = base64Result.substr(base64Result.indexOf(",") + 1);

      if (withoutType.trim() === "") {
        throw new Error("Couldn't transform image");
      }

      resolve(withoutType);
    };

    reader.onerror = error => reject(error);
  });
};

export const getFileExtension = (uri: string) => {
  const extension = uri.substr(uri.lastIndexOf(".") + 1);
  if (extension.trim() === "") return "jpg";
  return extension;
};

export const getImageUrl = async (file: File) => {
  try {
    const image64 = await getBase64(file);
    const extension = getFileExtension(file.name);
    const imageData = await postPhoto(image64, extension);
    const { url } = imageData ?? {};
    return url;
  } catch (e) {
    console.error(e.message);
  }
};

export const maxStringLength = (string = "", limit = 0) => {
  return string.substring(0, limit);
};

export const notify = (error: unknown, name = "Error") => {
  if (error instanceof Error) {
    error.name = name;
    Bugsnag.notify(error);
  }
  if (typeof error === "string") {
    const e = new Error(error);
    e.name = name;
    Bugsnag.notify(e);
  }
};
