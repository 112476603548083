import styled from "styled-components";

import { ErrorNotificationStyledProps as Props } from "./ErrorNotification.types";
import Notification from "components/global/notifications/Notification/Notification";

const ErrorNotificationStyled = styled(Notification)<Props>`
  padding: 1.8rem 2rem;
  background-color: var(--palette-purple-s66-l84);
  cursor: ${props => (props.clickable ? "pointer" : "auto")};

  .ErrorNotification {
    font-size: 1.6rem;
    color: var(--palette-black);
    line-height: 2rem;
    font-weight: 600;
  }
`;

export default ErrorNotificationStyled;
