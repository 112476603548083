import styled from "styled-components";

import { ProductCardStyledProps as Props } from "./ProductCard.types";
import { ProductCardPlaceholderStyledProps as PlaceholderProps } from "./ProductCard.types";
import CONSTANTS from "config/constants";

const { BREAKPOINTS } = CONSTANTS;
const { tablet } = BREAKPOINTS;

const ProductCardStyled = styled.a<Props>`
  text-decoration: none;
  color: var(--palette-blue-s51-l16);

  & > * {
    opacity: ${props => (props.available ? 1 : 0.6)};
    pointer-events: ${props => (props.available ? "all" : "none")};
  }

  .ProductCard {
    &__card {
      margin-top: 4rem;

      @media (max-width: ${tablet}px) {
        margin-top: 0;
      }

      &::before {
        content: "";
        position: absolute;
        display: flex;
        width: 28.6rem;
        height: 23rem;
        border-radius: 2.4rem;
        z-index: 0;
        background-color: var(--palette-white);
        bottom: 0;
      }
    }

    &__image {
      &-default {
        display: flex;
        justify-content: center;
      }
    }

    &__tags {
      display: flex;
      flex-direction: row;
      overflow: hidden;
      margin-top: 1rem;
      margin-left: 0.6rem;
      margin-right: 1.4rem;
      position: relative;
      z-index: 1;
    }

    &__tag {
      background-color: var(--palette-purple-s100-l99);
      padding: 0.8rem;
      margin: 0.5rem;
      border-radius: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__name {
      font-size: 2rem;
      font-weight: 600;
      margin-left: 1.2rem;
      color: var(--palette-black-s0-l10);
      margin-top: 0.8rem;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      width: 100%;
      position: relative;
      z-index: 1;
    }

    &__description {
      font-size: 1.4rem;
      color: var(--palette-gray-s0-l35);
      line-height: 2rem;
      margin-left: 1.2rem;
      margin-top: 1rem;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      width: 88%;
      position: relative;
      z-index: 1;
    }

    &__container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 1rem;
      position: relative;
      z-index: 1;
      margin-left: 1.2rem;
      padding-bottom: 2rem;

      &--location {
        display: flex;
        align-items: center;
      }

      &--button {
        font-size: 1.2rem;
      }
    }

    &__location {
      padding-left: 0.8rem;
      text-transform: capitalize;
      color: var(--palette-gray-s0-l50);
    }

    &__heart {
      position: relative;
      top: 6rem;
      right: -22rem;
      z-index: 1;
      background-color: var(--palette-gray-s0-l70-a30);
      border-radius: 1.2rem;
      border: unset;

      &--active {
        background-color: var(--palette-red-s85-l45);
      }

      &:hover:not([disabled]) {
        background-color: var(--palette-white);
        border: unset !important;
      }
    }
  }

  .ProductCardImage__image {
    border-radius: 1.5rem;
  }
`;

export const ProductCardPlaceholderStyled = styled.div<PlaceholderProps>`
  width: 100%;
  padding: 0 0.8rem;

  .ProductCardPlaceholder {
    &__image {
      background-color: var(--palette-gray-s0-l95);
      border-radius: 0.4rem;
      height: 20rem;
      overflow: hidden;
      position: relative;
      width: 100%;

      &__shine {
        animation: shine 2.5s ease-in-out infinite;
        background-color: var(--palette-gray-s0-l98);
        height: 350%;
        position: absolute;
        width: 25%;
        opacity: 0.4;
      }
    }

    &__price {
      background-color: var(--palette-gray-s0-l95);
      border-radius: 0.4rem;
      height: 1.6rem;
      margin: 1.2rem 0 0.8rem;
      overflow: hidden;
      position: relative;
      width: 4.5rem;

      &__shine {
        animation: shine 2.5s ease-in-out infinite;
        background-color: var(--palette-gray-s0-l98);
        height: 300%;
        position: absolute;
        width: 25%;
        opacity: 0.4;
      }
    }

    &__name {
      background-color: var(--palette-gray-s0-l95);
      border-radius: 0.4rem;
      height: 1.6rem;
      overflow: hidden;
      position: relative;
      width: 8.4rem;

      &__shine {
        animation: shine 2.5s ease-in-out infinite;
        background-color: var(--palette-gray-s0-l98);
        height: 300%;
        position: absolute;
        width: 25%;
        opacity: 0.4;
      }
    }
  }
`;

export default ProductCardStyled;
