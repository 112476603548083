// Stores service hooks
import { useQuery } from "react-query";

import { fetchDefaultStore, fetchStoreDetails } from "./stores.service";
import { fetchNearbyStores, fetchStores } from "./stores.service";
import { Google } from "types/geo.types";
import useGeo from "contexts/geo/geo.hooks";
import useCatalogues from "contexts/catalogues/catalogues.hooks";
import { notify } from "utils/common.utils";

export const useFetchDefaultStore = () => {
  const { selectedCatalogueId } = useCatalogues();

  return useQuery(
    ["stores", "default", selectedCatalogueId],
    () => fetchDefaultStore(selectedCatalogueId),
    {
      staleTime: 60 * 1000 * 60,
      onError: (e: Error) => {
        notify(e, "Get default store request");
      }
    }
  );
};

export const useFetchStoreDetails = (storeId: number) => {
  return useQuery(["stores", storeId], () => fetchStoreDetails(storeId), {
    staleTime: 60 * 1000 * 60,
    onError: (e: Error) => {
      notify(e, "Get store details request");
    }
  });
};

export const useFetchNearbyStores = (coords?: Google.Coordinates) => {
  const { selectedCoordinates } = useGeo();
  const { lat, lng } = coords ?? selectedCoordinates ?? {};
  const { selectedCatalogueId } = useCatalogues();

  return useQuery(
    ["stores", { lat, lng, selectedCatalogueId }],
    () => fetchNearbyStores(lat!, lng!, selectedCatalogueId),
    {
      enabled: typeof lat !== "undefined" && typeof lng !== "undefined",
      onError: (e: Error) => {
        notify(e, "Get nearby stores request");
      }
    }
  );
};

export const useFetchStores = (query?: string) => {
  const { selectedCatalogueId } = useCatalogues();

  return useQuery(
    ["stores", { selectedCatalogueId, query }],
    () => fetchStores(selectedCatalogueId, query),
    {
      onError: (e: Error) => {
        notify(e, "Get stores request");
      }
    }
  );
};
