// Auth context
import React, { createContext, FC, useCallback, useMemo } from "react";
import { useEffect, useState } from "react";
import { ContextDevTool } from "react-context-devtool";
import firebase from "firebase";

import { AuthProviderProps as Props } from "./auth.context.types";
import { AuthProviderValue } from "./auth.context.types";
import { auth, Services } from "config/artisn.config";

// @ts-ignore
export const AuthContext = createContext<AuthProviderValue>({});

const AuthProvider: FC<Props> = props => {
  const [isAnonymous, setIsAnonymous] = useState<boolean>();
  const [uid, setUid] = useState("");
  const [providerData, setProviderData] = useState<
    (firebase.UserInfo | null)[]
  >([]);

  const signInAnonymously = async () => {
    return await auth().signInAnonymously();
  };

  const subscriber = useCallback((user: firebase.User | null) => {
    const { uid: userId, isAnonymous: isUserAnonymous } = user ?? {};
    const { providerData: provider } = user ?? {};
    setIsAnonymous(!!isUserAnonymous);
    if (provider) {
      setProviderData(provider);
    }
    if (userId) {
      setUid(userId);
      return;
    }
    signInAnonymously();
  }, []);

  useEffect(() => {
    const authSubscriber = auth().onAuthStateChanged(subscriber);

    return authSubscriber;
  }, [subscriber]);

  const signInWithEmailAndPassword = async (
    email: string,
    password: string
  ) => {
    return await auth().signInWithEmailAndPassword(email, password);
  };

  const sendPasswordResetEmail = async (email: string) => {
    return await auth().sendPasswordResetEmail(email);
  };

  const signInWithGoogle = async () => {
    const provider = new Services.GoogleAuthProvider();
    const response = await auth().signInWithPopup(provider);
    const { credential } = response;
    if (!credential || auth().currentUser?.isAnonymous) return response;
    await auth().signInWithCredential(credential);
    return response;
  };

  const unlinkGoogle = useCallback(async () => {
    const provider = new Services.GoogleAuthProvider();
    const user = await auth().currentUser?.unlink(provider.providerId);
    if (user) subscriber(user);
  }, [subscriber]);

  const signInWithFacebook = async () => {
    const provider = new Services.FacebookAuthProvider();
    const response = await auth().signInWithPopup(provider);
    const { credential } = response;
    if (!credential || auth().currentUser?.isAnonymous) return response;
    await auth().signInWithCredential(credential);
    return response;
  };

  const unlinkFacebook = useCallback(async () => {
    const provider = new Services.FacebookAuthProvider();
    const user = await auth().currentUser?.unlink(provider.providerId);
    if (user) subscriber(user);
  }, [subscriber]);

  const registerWithEmailAndPassword = useCallback(
    async (email: string, password: string) => {
      const credential = Services.EmailAuthProvider.credential(email, password);
      const response = await auth().currentUser?.linkWithCredential(credential);
      if (response) subscriber(response.user);
      return response;
    },
    [subscriber]
  );

  const getEmail = () => {
    return auth()?.currentUser?.email;
  };

  const value: AuthProviderValue = useMemo(() => {
    return {
      isAnonymous,
      signInWithEmailAndPassword,
      signInWithGoogle,
      signInWithFacebook,
      signInAnonymously,
      sendPasswordResetEmail,
      registerWithEmailAndPassword,
      uid,
      email: getEmail(),
      unlinkFacebook,
      unlinkGoogle,
      providerData,
      setProviderData
    };
  }, [
    isAnonymous,
    registerWithEmailAndPassword,
    uid,
    providerData,
    unlinkFacebook,
    unlinkGoogle
  ]);

  return (
    <AuthContext.Provider value={value}>
      <ContextDevTool context={AuthContext} id="auth" displayName="Auth" />
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
