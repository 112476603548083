import { useEffect } from "react";
import { events } from "@artisan-commerce/analytics-capacitor";
import { Storage } from "@capacitor/storage";

import CONSTANTS from "config/constants";
import useGeo from "contexts/geo/geo.hooks";
import useShippingAddress from "contexts/shippingAddress/shippingAddress.hooks";

const { SELECTED_COORDINATES_TOKEN } = CONSTANTS.STORAGE;
const { logSetLocation, logChangeLocation } = events.geo;

const useListenGeo = () => {
  const { selectedShippingAddress } = useShippingAddress();
  const { selectedCoordinates, setSelectedCoordinates } = useGeo();
  const { deviceCoordinates } = useGeo();

  /**
   * Change selected coordinates every time selected shipping address changes.
   *
   * For cases where the user doesn't have any stored shipping addresses, we
   * also save the last selected user coordinates as a fallback.
   *
   */
  useEffect(() => {
    (async () => {
      const { value } = await Storage.get({
        key: SELECTED_COORDINATES_TOKEN
      });
      const latestCoordinates = value ? JSON.parse(value) : undefined;

      if (!selectedShippingAddress && !latestCoordinates && !deviceCoordinates)
        return;

      setSelectedCoordinates(prev => {
        const { lat: previousLat, lng: previousLng } = prev ?? {};
        const coords =
          selectedShippingAddress ?? latestCoordinates ?? deviceCoordinates;
        const { lat, lng } = coords;

        if (
          typeof previousLat === "undefined" ||
          typeof previousLng === "undefined"
        ) {
          logSetLocation({ lat, lng });
        } else {
          logChangeLocation({
            previousLat,
            previousLng,
            nextLat: lat,
            nextLng: lng
          });
        }

        return { lat, lng };
      });
    })();
  }, [selectedShippingAddress, deviceCoordinates, setSelectedCoordinates]);

  /* Save selected coordinates on local storage. */
  useEffect(() => {
    if (!selectedCoordinates) return;
    Storage.set({
      key: SELECTED_COORDINATES_TOKEN,
      value: JSON.stringify(selectedCoordinates)
    });
  }, [selectedCoordinates]);
};

export default useListenGeo;
