// Project constants
import { Capacitor } from "@capacitor/core";

import { Country } from "types/country.types";
import { ArtisnPlatform, Catalogue, Vendor } from "artisn/types";
import { PickUpOption } from "types/common.types";
import PackageJSON from "../../package.json";

const ECUADOR: Country = {
  id: 1,
  name: "Ecuador",
  code: "593",
  isoCode: "EC",
  locale: "es",
  flag: "🇪🇨",
  currency: "USD",
  currencySymbol: "$",
  decimals: 2,
  lat: -0.182259,
  lng: -78.484441,
  bounds: [
    { lat: -5.357689, lng: -75.05175 },
    { lat: 1.052285, lng: -75.255764 },
    { lat: 1.979348, lng: -82.817828 },
    { lat: -4.68279, lng: -81.307613 }
  ]
};

export const DELIVERY: Catalogue = {
  catalogueId: "1",
  name: "Delivery",
  active: true
};

export const PICK_UP: Catalogue = {
  catalogueId: "3",
  name: "Pickup",
  active: true
};

const DEFAULT_VENDOR: Vendor = {
  id: "328",
  name: "default",
  active: true,
  description: "",
  images: [],
  maxPurchaseValue: -1,
  sponsored: true
};

export const DEFAULT_PICK_UP_OPTION = {
  id: 0,
  name: "Quiero mi pedido ahora",
  forNow: true
};

export const PICK_UP_OPTIONS: PickUpOption[] = [
  DEFAULT_PICK_UP_OPTION,
  { id: 1, name: "Hora programada", forNow: false }
];

export const FACEBOOK_PIXEL_WEB_ID = "251295482863541";

const WITH_PICK_UP = true;
const WITH_DELIVERY = false;
const WITH_PURCHASE = WITH_PICK_UP || WITH_DELIVERY;
const WITH_MULTIPLE_PURCHASE_METHODS = WITH_DELIVERY && WITH_PICK_UP;
const WHATSAPP_PHONE_NUMBER = "14028350421";
const DEFAULT_CATALOGUE = WITH_DELIVERY ? DELIVERY : PICK_UP;
const DEFAULT_CITY = { id: 19, name: "QUITO" };
const DEFAULT_IMAGE = "/assets/images/default-pet-image.svg";
const DOMAIN = "www.adopcionanimal.ec";

const CONSTANTS = {
  // HTML title tag text (Useful to display the brand name for SEO)
  TITLE: "ADOPCIÓNANIMAL.EC",
  // Project configurations
  ARTISN: {
    // Project Account ID
    ACCOUNT_ID: 25,
    // Default Vendor
    DEFAULT_VENDOR,
    // Vendors
    VENDORS: [DEFAULT_VENDOR],
    // Default catalogue
    DEFAULT_CATALOGUE,
    // Catalogues
    CATALOGUES: [DELIVERY, PICK_UP],
    // Default country
    DEFAULT_COUNTRY: ECUADOR,
    // Catalogues
    COUNTRIES: [ECUADOR],
    // Default city
    DEFAULT_CITY,
    // Default pet image
    DEFAULT_IMAGE,
    // Domain
    DOMAIN,
    // The types of the goods being sold (Useful for analytics)
    CONTENT_TYPE: "food",
    // Shopping cart default name
    SHOPPING_CART_DEFAULT_NAME: "default",
    // Shopping cart wishlist name
    SHOPPING_CART_WISHLIST_NAME: "wishlist",
    // Payment methods ids
    PAYMENT_METHODS: {
      // Pay with cash
      CASH_ID: 1,
      // Pay with card
      CARD_ID: 2
    },
    // Payment providers ids
    PAYMENT_PROVIDERS: {
      // Pay with kushki
      KUSHKI_ID: 14,
      // Pay with PayPhone
      PAY_PHONE_ID: 8,
      // Pay with place to pay
      PLACE_TO_PAY_ID: 4
    }
  },
  // REST API configurations
  API: {
    // Artisn Rest API url
    API_URL: "https://api.artisn.io",
    // Mock services, change to false if you don't want to mock
    MOCK_SERVICES: false,
    // If true a registered user with address, billing data and orders will be present
    MOCK_WITH_INITIAL_USER_DATA: true,
    // How many category groups to fetch
    CATEGORIES_PER_REQUEST: 5,
    // How many products inside each category group to fetch
    PRODUCTS_PER_CATEGORY_REQUEST: 10,
    // Default max waiting time for a request reply
    DEFAULT_REQUEST_TIMEOUT: 10000
  },
  // Activate or deactivate features
  FEATURE_FLAGS: {
    // Whether loyalty functionality should be enabled
    WITH_LOYALTY: false,
    // Whether coupons functionality should be enabled
    WITH_COUPONS: false,
    // Whether pickup functionality should be enabled
    WITH_PICK_UP,
    // Whether delivery functionality should be enabled
    WITH_DELIVERY,
    // Whether the app can sell
    WITH_PURCHASE,
    // Whether the user can select a purchase method
    WITH_MULTIPLE_PURCHASE_METHODS,
    // Whether wishlist functionality should be enabled
    WITH_WISHLIST: true,
    // Allow a product to be shared
    WITH_SHARE_PRODUCT: true,
    // Allow a shopping cart to be shared
    WITH_SHARE_SHOPPING_CART: false,
    // Allow anonymous shopping cart merge
    WITH_ANONYMOUS_SHOPPING_CART_TOKEN: false,
    // Allow anonymous flow
    WITH_ANONYMOUS: true,
    // Show the product details modal if needed
    // If not add product to cart without navigating to the details page
    // Show notification when product is added
    WITH_ADD_DIRECTLY_TO_CART: false,
    // Show product modal
    WITH_PRODUCT_MODAL: false,
    // Show cart drawer
    WITH_CART_DRAWER: false,
    // Show upsale modal
    WITH_UPSALE_MODAL: false,
    // Show crossale modal
    WITH_CROSSALE_MODAL: false,
    // Store coupons
    WITH_STORE_COUPONS: false,
    // Show talk shop
    WITH_TALK_SHOP: false,
    // Show SelectAddressDropdown component in Navbar
    WITH_SELECT_ADDRESS_DROPDOWN: false,
    // Allows the user to activate or deactivate the dark mode
    WITH_DARK_MODE: false,
    // Show AnonymousPasswordForm component in Checkout
    WITH_CHECKOUT_SIGNUP: false,
    // Activates the express checkout mode for payphone payments
    WITH_PAYPHONE_EXPRESS_CHECKOUT: false,
    // Enable logging with bugsnag
    WITH_BUGSNAG: false
  },
  // Limit user actions
  CONSTRAINTS: {
    // Number of cards a user can have
    MAX_NUMBER_OF_CARDS: 3,
    // Number of shipping addresses a user can have
    MAX_NUMBER_OF_SHIPPING_ADDRESSES: 5,
    // Number of billing data a user can have
    MAX_NUMBER_OF_BILLING_DATA: 5
  },
  // Store user preferences
  STORAGE: {
    // Shipping address local storage token
    SHIPPING_ADDRESS_TOKEN: "artisn-selected-shipping-address",
    // Billing data local storage token
    BILLING_DATA_TOKEN: "artisn-selected-billing-data",
    // Card local storage token
    CARD_TOKEN: "artisn-selected-card",
    // Recent category local storage token
    CATEGORY_TOKEN: "artisn-recent-category",
    // Selected coordinates local storage token
    SELECTED_COORDINATES_TOKEN: "artisn-selected-coordinates",
    // Local storage theme preference key
    THEME_PREFERENCE_TOKEN: "artisn-theme-preference",
    // Anonymous shopping cart
    ANONYMOUS_SHOPPING_CART_TOKEN: "anonymous-shopping-cart",
    // Transfer anonymous id
    TRANSFER_ANONYMOUS_ID: "transfer-anonymous-id",
    // Pending order storage token
    PENDING_ORDER_TOKEN: "artisn-pending-order",
    // Checkout local storage form data
    CHECKOUT_FORM_DATA_TOKEN: "checkout-form-data"
  },
  // CSS Break Points
  BREAKPOINTS: {
    mobile: 420,
    tablet: 768,
    desktop: 1024,
    wide: 1440,
    hd: 2560
  },
  // General settings
  GENERAL: {
    // Excluded status codes that should not be logged
    EXCLUDED_LOGGER_STATUS_CODES: [422],
    // Included environments that should be logged
    INCLUDED_LOGGER_ENVS: ["staging", "production"],
    // Included Internationalization environments that should be logged to the
    // console
    INCLUDED_INTL_LOG_ENVS: ["production"],
    // Platform running the application
    PLATFORM: Capacitor.getPlatform() as ArtisnPlatform,
    // App version code to enable update and support screen
    VERSION_CODE: PackageJSON.version
  },
  // Date and time format strings
  DATES_FORMAT: {
    HOUR_MINUTE: "HH:mm",
    COMPLETE_DATE_TIME: "YYYY-MM-DD HH:mm:ss"
  },
  INTEGRATIONS: {
    WHATSAPP: {
      PHONE_NUMBER: WHATSAPP_PHONE_NUMBER,
      RETURN_TO_APP_URL: `https://api.whatsapp.com/send/?phone=${WHATSAPP_PHONE_NUMBER}&text&app_absent=0`
    }
  },
  // App settings
  APP: {
    PLAY_STORE_URL: "",
    APPLE_STORE_URL: "",
    WEB_URL: ""
  }
};

export default CONSTANTS;
