// Images services
import axiosDefault from "utils/axios.utils";
import { shouldMock } from "utils/common.utils";
import { S3Response } from "./images.service.types";
import { buildHeaders } from "utils/services.utils";

const baseUrl = "api/upload-files/imageBase64";

export const postPhoto = async (
  imageBase64: string | ArrayBuffer,
  extension: string
): Promise<S3Response | undefined> => {
  try {
    if (!shouldMock) {
      const { data } = await axiosDefault.post(
        baseUrl,
        {
          extension,
          imageBase64,
          bucket: "plugins.prod",
          typeFile: "image",
          path: "mosumi/checkout/images/"
        },
        {
          headers: await buildHeaders()
        }
      );

      return data;
    } else {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(undefined);
        }, 1000);
      });
    }
  } catch (e) {
    throw new Error(e.message);
  }
};
