import useListenUser from "./listeners/useListenUser";
import useListenGeo from "./listeners/useListenGeo";
import useListenStores from "./listeners/useListenStores";
import useListenAddresses from "./listeners/useListenAddresses";
import useListenVendor from "./listeners/useListenVendor";
import useListenShoppingCart from "./listeners/useListenShoppingCart";
import useListenBillingData from "./listeners/useListenBillingData";
import useListenPayments from "./listeners/useListenPayments";
import useListenGlobals from "./listeners/useListenGlobals";
import useListenCatalogue from "./listeners/useListenCatalogue";
import useListenCheckoutFormData from "./listeners/useListenCheckoutFormData";

const useListeners = () => {
  useListenUser();
  useListenGeo();
  useListenStores();
  useListenVendor();
  useListenAddresses();
  useListenBillingData();
  useListenShoppingCart();
  useListenPayments();
  useListenCatalogue();
  useListenGlobals();
  useListenCheckoutFormData();
};

export default useListeners;
