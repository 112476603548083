import React from "react";

import Styles from "./SuccessNotification.styles";
import { SuccessNotificationProps as Props } from "./SuccessNotification.types";

const SuccessNotification: React.FC<Props> = props => {
  const { className, message, title } = props;
  const text = message ? `${title}: ${message}` : title;

  return (
    <Styles
      className={`SuccessNotification ${className}`}
      showCloseIcon={false}
    >
      <p>{text}</p>
    </Styles>
  );
};

SuccessNotification.defaultProps = {};

export default SuccessNotification;
