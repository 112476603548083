import { lighten, darken } from "polished";

const primary = "hsla(16, 64%, 52%, 1)";

const palette = {
  empty: "", // Fixes a bug with styled components that appends a coma
  "primary-dark": darken(0.12, primary),
  "primary-light": lighten(0.47, primary),
  // This variable is for capacitor use only, it represents the primary color.
  "primary-hex": "#D36137",
  "purple-s85-l45": primary,
  "purple-s66-l84": "hsla(352, 66%, 84%, 1)",
  "purple-s100-l99": "hsla(351, 100%, 99%, 1)",
  "purple-s63-l90": "hsla(15, 63%, 90%, 1)",
  "cream-s42-l93": "hsla(45, 42%, 93%, 1)",
  "brown-s42-l93": "hsla(30, 45%, 41%, 1)",
  primary: "var(--palette-purple-s85-l45)",
  "green-s100-l40": "hsla(88, 100%, 40%, 1)",
  "green-s63-l42": "hsla(145, 63%, 42%, 1)",
  "green-s37-l51": "hsla(153, 37%, 51%, 1)",
  "green-s74-l85": "hsla(147, 74%, 85%, 1)",
  "green-s65-l97": "hsla(147, 65%, 97%)",
  "red-s61-l87": "hsla(0, 61%, 87%, 1)",
  "red-s60-l60": "hsla(2, 60%, 60%, 1)",
  "red-s79-l63": "hsla(0, 79%, 63%, 1)",
  "red-s85-l45": "hsla(16, 74%, 45%, 1)",
  "blue-s51-l16": "hsla(193, 51%, 16%, 1)",
  "blue-s89-l52": "hsla(214, 89%, 52%, 1)",
  "yellow-s100-l55": "hsla(50, 100%, 54%, 1)",
  // This variable is for capacitor use only, it represents the black color.
  "gray-s210-l4-a52": "hsla(210, 4%, 52%, 1)",
  "black-hex": "#000000",
  black: "hsla(0, 0%, 0%, 1)",
  "black-s50-a50": "hsla(0,50%,0%,0.5)",
  "black-s0-l10": "hsla(0, 0%, 10%, 1)",
  "gray-s0-l20-a04": "hsla(0, 0%, 20%, 0.04)",
  "gray-s0-l20-a08": "hsla(0, 0%, 20%, 0.08)",
  "gray-s0-l20-a16": "hsla(0, 0%, 20%, 0.16)",
  "gray-s0-l20-a20": "hsla(0, 0%, 20%, 0.20)",
  "gray-s0-l20-a70": "hsla(0, 0%, 20%, 0.70)",
  "gray-s0-l20": "hsla(0, 0%, 20%, 1)",
  "gray-s0-l21": "hsla(0, 0%, 21%, 1)",
  "gray-s0-l30": "hsla(0, 0%, 30%, 1)",
  "gray-s0-l35": "hsla(0, 0%, 35%, 1)",
  "gray-s0-l70-a30": "hsla(0, 0%, 70%, 0.3)",
  "gray-s0-l40-a40": "hsla(0, 0%, 40%, 0.4)",
  "gray-s0-l40": "hsla(0, 0%, 40%, 1)",
  "gray-s0-l50": "hsla(0, 0%, 50%, 1)",
  "gray-s0-l70-a48": "hsla(0, 0%, 70%, 0.48)",
  "gray-s0-l70": "hsla(0, 0%, 70%, 1)",
  "gray-s0-l78": "hsla(0, 0%, 78%, 1)",
  "gray-s0-l80": "hsla(0, 0%, 80%, 1)",
  "gray-s3-l85": "hsla(0, 3%, 85%, 1)",
  "gray-s0-l85": "hsla(0, 0%, 85%, 1)",
  "gray-s0-l90": "hsla(0, 0%, 90%, 1)",
  "gray-s0-l92": "hsla(0, 0%, 92%, 1)",
  "gray-s0-l95": "hsla(0, 0%, 95%, 1)",
  "gray-s0-l98": "hsla(0, 0%, 98%, 1)",
  transparent: "hsla(0, 0%, 0%, 0)",
  // This variable is for capacitor use only, it represents the white color.
  "white-hex": "#ffffff",
  white: "hsla(0, 0%, 100%, 1)",
  text: {
    primary: "var(--palette-primary)",
    white: "var(--palette-white)"
  }
};

const fonts = {
  primary: "'Montserrat', sans-serif"
};

const sizes = {
  page: {
    minWidth:
      "calc(100vw - (100vw - 100%) - env(safe-area-inset-left) - env(safe-area-inset-right))",
    maxWidth:
      "calc(100vw - (100vw - 100%) - env(safe-area-inset-left) - env(safe-area-inset-right))",
    minHeight:
      "calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom))",
    maxHeight:
      "calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom))",
    columns:
      "minmax(1.6rem, 1fr) [content-start] minmax(0, 120rem) [content-end] minmax(1.6rem, 1fr)"
  },
  navbar: {
    height: "calc(10.4rem + env(safe-area-inset-top))",
    "height-mobile": "calc(8.9rem + env(safe-area-inset-top))",
    "height-mobile-without-search": "calc(5.8rem + env(safe-area-inset-top))"
  }
};

const variables = { palette, fonts, sizes };

export default variables;
