import React, { useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { PhoneNumberInput, PhoneNumberInputValues } from "artisn-ui-react";
import { TextInput } from "artisn-ui-react";
import { events } from "@artisan-commerce/analytics-capacitor";

import Styles from "./AddressForm.styles";
import { AddressFormValues } from "./AddressForm.types";
import { AddressFormProps as Props } from "./AddressForm.types";
import { getSelectedPostPayload } from "./AddressForm.helpers";
import { AddressFormSchema, getDefaultNickname } from "./AddressForm.helpers";
import { getSelectedPutPayload } from "./AddressForm.helpers";
import Button from "components/global/Button/Button";
import { PostShippingAddressPayload } from "services/shippingAddress/shippingAddress.service.types";
import { PutShippingAddressPayload } from "services/shippingAddress/shippingAddress.service.types";
import { useFetchShippingAddresses } from "services/shippingAddress/shippingAddress.service.hooks";
import { usePostShippingAddress } from "services/shippingAddress/shippingAddress.service.hooks";
import { usePutShippingAddress } from "services/shippingAddress/shippingAddress.service.hooks";
import { defaultFunction } from "utils/common.utils";
import Checkbox from "components/global/Checkbox/Checkbox";
import useCountries from "contexts/countries/countries.hooks";
import useAuth from "contexts/auth/auth.context.hooks";
import useShippingAddress from "contexts/shippingAddress/shippingAddress.hooks";
import CONSTANTS from "config/constants";
import { trimFields } from "utils/form.utils";

import HomeSVG from "../../../../public/assets/images/home.svg";
import WorkSVG from "../../../../public/assets/images/work.svg";
import HeartLineSVG from "../../../../public/assets/images/heart-line.svg";

const { logAddShippingAddress, logUpdateShippingAddress } = events.shipping;

const AddressForm: React.FC<Props> = props => {
  const { className, initialValues, method, mapAddress } = props;
  const { onPressButton = defaultFunction, disabled, editAddress } = props;
  const [errorMessage, setErrorMessage] = useState("");
  const [nicknameErrorMessage, setNicknameErrorMessage] = useState("");
  const number = initialValues?.numberContactAddress;
  const { data: addressList } = useFetchShippingAddresses();
  const { selectedCountry, countryISOCodes } = useCountries();
  const { isAnonymous } = useAuth();
  const { setSelectedShippingAddress } = useShippingAddress();
  const { isoCode: defaultCountryISOCode } = selectedCountry;
  const { handleSubmit, register, formState, control, getValues, setError } =
    useForm({
      mode: "onChange",
      resolver: yupResolver(AddressFormSchema),
      defaultValues: {
        ...initialValues,
        numberContactAddress: number,
        nickname: getDefaultNickname(initialValues?.nickname),
        otherNickname: initialValues?.nickname,
        default: !addressList?.length ? true : initialValues?.default
      }
    });
  const [phone, setPhone] = useState<PhoneNumberInputValues>({
    countryCallingCode: "",
    countryCode: defaultCountryISOCode,
    international: "",
    internationalRaw: "",
    isPossible: true,
    isValid: true,
    national: getValues().numberContactAddress ?? "",
    nationalRaw: ""
  });
  const watchNickname = useWatch({ control, name: "nickname" });
  const watchOtherNickname = useWatch({ control, name: "otherNickname" });
  const selectedNickname = watchNickname === "Otro";
  const showInput = selectedNickname
    ? "AddressForm__nickname-input--show"
    : null;
  const {
    mutate: postMutate,
    reset: postReset,
    isLoading: isLoadingPost
  } = usePostShippingAddress();
  const {
    mutate: putMutate,
    reset: putReset,
    isLoading: isLoadingPut
  } = usePutShippingAddress();
  const { reference, numberContactAddress } = formState.errors ?? {};
  const { addressNumber, secondaryStreet, mainStreet } = formState.errors ?? {};

  const createAddress = (postAddress: PostShippingAddressPayload) => {
    const { mainStreet, secondaryStreet, number, nickname } = postAddress;

    postMutate(postAddress, {
      onError: () => {
        setErrorMessage("Ocurrió un problema al guardar la dirección de envío");
        console.error("An error occurred in the POST request");
      },
      onSuccess: () => {
        logAddShippingAddress({
          address: `${mainStreet} ${secondaryStreet} ${number}`,
          alias: nickname
        });
        onPressButton();
        postReset();
      }
    });
  };

  const updateAddress = (putAddress: PutShippingAddressPayload) => {
    const { mainStreet, secondaryStreet, number, id } = putAddress;

    putMutate(putAddress, {
      onError: () => {
        setErrorMessage(
          "Ocurrió un problema al actualizar la dirección de envío"
        );
        console.error("An error occurred in the POST request");
      },
      onSuccess: () => {
        logUpdateShippingAddress({
          address: `${mainStreet} ${secondaryStreet} ${number}`,
          addressId: id,
          fields: Object.keys(formState.touchedFields)
        });
        onPressButton();
        putReset();
      }
    });
  };

  const submitHandler = (form: AddressFormValues) => {
    const newForm = trimFields(form);
    if (!phone.isValid) {
      setError("numberContactAddress", {
        message: "El teléfono ingresado no es válido"
      });
      return;
    }
    setErrorMessage("");
    if (selectedNickname && !watchOtherNickname) {
      setNicknameErrorMessage("Campo requerido");
      return;
    }
    if (isAnonymous) {
      setSelectedShippingAddress({
        ...getSelectedPostPayload(newForm, mapAddress, selectedCountry),
        id: 1,
        createdAt: "",
        country: {
          id: CONSTANTS.ARTISN.DEFAULT_COUNTRY.id,
          name: CONSTANTS.ARTISN.DEFAULT_COUNTRY.name
        }
      });
      onPressButton();
      return;
    }
    if (method === "POST") {
      createAddress(
        getSelectedPostPayload(newForm, mapAddress, selectedCountry)
      );
    }
    if (method === "PUT") {
      updateAddress(
        getSelectedPutPayload(
          newForm,
          mapAddress,
          selectedCountry,
          initialValues!,
          editAddress!
        )
      );
    }
  };

  return (
    <Styles className={`AddressForm ${className}`}>
      <form onSubmit={handleSubmit(submitHandler)}>
        <TextInput
          {...register("mainStreet")}
          className="AddressForm__text-input field"
          label="Calle principal"
          placeholder="Av Orellana"
          errorMessage={mainStreet?.message}
          maxLength={50}
        />
        <TextInput
          {...register("addressNumber")}
          className="AddressForm__text-input field"
          label="Número de vivienda"
          placeholder="E3 40"
          errorMessage={addressNumber?.message}
          maxLength={50}
        />
        <TextInput
          {...register("secondaryStreet")}
          className="AddressForm__text-input field"
          label="Calle secundaria"
          placeholder="Av Amazonas"
          errorMessage={secondaryStreet?.message}
          maxLength={50}
        />
        <TextInput
          {...register("reference")}
          className="AddressForm__text-input field"
          label="Referencia"
          placeholder="Metropolitan Park / Ecuanimsa"
          errorMessage={reference?.message}
          maxLength={50}
        />
        <Controller
          name="numberContactAddress"
          control={control}
          defaultValue={getValues().numberContactAddress}
          render={({ field }) => (
            <PhoneNumberInput
              {...field}
              className="field"
              label="Número de teléfono para confirmación"
              countriesCodes={countryISOCodes}
              onChange={e => {
                setPhone(e);
                field.onChange(e.national);
              }}
              placeholder="099 999 9999"
              errorMessage={numberContactAddress?.message}
              inputValue={phone.national}
              maxLength={12}
            />
          )}
        />
        <div className="AddressForm__buttonGroup">
          <div className="AddressForm__buttonGroup__title">Etiqueta</div>
          <div className="AddressForm__buttonGroup__options">
            <label className="AddressForm__option">
              <input
                {...register("nickname")}
                type="radio"
                value="Casa"
                className="AddressForm__option__radio"
              />
              <span className="AddressForm__option__button">
                <HomeSVG />
                Casa
              </span>
            </label>
            <label className="AddressForm__option">
              <input
                {...register("nickname")}
                type="radio"
                value="Trabajo"
                className="AddressForm__option__radio"
              />
              <span className="AddressForm__option__button">
                <WorkSVG />
                Trabajo
              </span>
            </label>
            <label className="AddressForm__option">
              <input
                {...register("nickname")}
                type="radio"
                value="Otro"
                className="AddressForm__option__radio"
              />
              <span className="AddressForm__option__button">
                <HeartLineSVG />
                Otro
              </span>
            </label>
          </div>
          <TextInput
            {...register("otherNickname")}
            className={`AddressForm__nickname-input ${showInput} field `}
            placeholder="Ej: Mi Casa"
            errorMessage={nicknameErrorMessage}
            maxLength={50}
          />
          {method === "POST" && addressList?.length !== 0 && !isAnonymous ? (
            <Checkbox
              {...register("default")}
              label="Usar como predeterminada"
              name="default"
              className="AddressForm__checkbox field"
            />
          ) : null}
        </div>
        {errorMessage ? (
          <p className="AddressForm__error-message">{errorMessage}</p>
        ) : null}
        <Button
          className="AddressForm__button"
          isLoading={isLoadingPost || isLoadingPut}
          htmlType="submit"
          disabled={disabled}
        >
          Guardar dirección
        </Button>
      </form>
    </Styles>
  );
};

AddressForm.defaultProps = {
  className: ""
};

export default AddressForm;
