import styled from "styled-components";

import { SuccessNotificationStyledProps as Props } from "./SuccessNotification.types";
import Notification from "components/global/notifications/Notification/Notification";

const SuccessNotificationStyled = styled(Notification)<Props>`
  padding: 1.8rem 2rem;
  border: none;
  background-color: var(--palette-green-s74-l85);

  .SuccessNotification {
    color: var(--palette-black);
    font-weight: 600;
    line-height: 2rem;
    font-size: 1.6rem;
  }
`;

export default SuccessNotificationStyled;
