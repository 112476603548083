import React, { useState, useEffect } from "react";
import { Store } from "artisn/types";
import { SearchBar } from "artisn-ui-react";
import { events } from "@artisan-commerce/analytics-capacitor";

import { Google } from "types/geo.types";
import Styles from "./SearchStore.styles";
import InfoCard from "../InfoCard/InfoCard";
import { dropdownConfigs } from "utils/common.utils";
import { SearchStoreContentStyled } from "./SearchStore.styles";
import { SearchStoreProps as Props } from "./SearchStore.types";
import { useFetchNearbyStores } from "services/stores/stores.service.hooks";
import { useFetchCoordsFromGoogleAddress } from "services/geo/geo.service.hooks";
import { debounce } from "utils/performance.utils";
import useAnalytics from "contexts/analytics/analytics.context.hooks";
import { dismissErrorNotification } from "utils/notifications.utils";
import { createErrorNotification } from "utils/notifications.utils";

const { logFindLocation } = events.geo;

const SearchStore: React.FC<Props> = props => {
  const { onChange } = props;
  const { analyticsInitialized } = useAnalytics();
  const [query, setQuery] = useState<string | undefined>();
  const [searchResults, setSearchResults] = useState<
    Google.Coordinates | undefined
  >();
  const [showDropdown, setShowDropdown] = useState(false);

  const { data: nearbyStores } = useFetchNearbyStores(searchResults);
  const { data, error } = useFetchCoordsFromGoogleAddress(query);

  const submitHandler = (query: string) => {
    setQuery(query);
  };

  const changeHandler = (item: Store) => {
    setShowDropdown(false);
    onChange(item);
  };

  const onInputClick = () => {
    setShowDropdown(true);
  };

  const clearHandler = () => {
    setQuery(undefined);
    setShowDropdown(false);
  };

  const queryChangeHandler = debounce((value: string) => {
    if (value.length < 3) return;
    setQuery(value);
  }, 500);

  useEffect(() => {
    if (!data || !analyticsInitialized) return;
    const { location } = data.geometry;
    setSearchResults(location);
    logFindLocation();
  }, [data, analyticsInitialized]);

  useEffect(() => {
    if (!error) return;
    dismissErrorNotification();
    createErrorNotification(
      "Hubo un error al traer el resultado de la búsqueda"
    );
    console.error(error);
  }, [error]);

  return (
    <Styles className="SearchStore">
      <SearchBar
        className="SearchStore__search-bar field"
        placeholder="Busca una dirección"
        onSubmit={submitHandler}
        onInputClick={onInputClick}
        dropdownConfigs={{ ...dropdownConfigs, showDropdown }}
        onClear={clearHandler}
        onChange={queryChangeHandler}
        onInputBlur={() => setShowDropdown(false)}
      >
        {nearbyStores ? (
          <SearchStoreContentStyled className="SearchStoreContent">
            {nearbyStores.map((item, index) => {
              const { address, storeName } = item;
              return (
                <InfoCard
                  className="SearchStoreContent__store-info"
                  key={index}
                  title={address}
                  value={storeName}
                  onContentClick={() => changeHandler(item)}
                />
              );
            })}
          </SearchStoreContentStyled>
        ) : null}
      </SearchBar>
    </Styles>
  );
};

SearchStore.defaultProps = {};

export default SearchStore;
