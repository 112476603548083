import { useEffect } from "react";
import { useRouter } from "next/router";

import useAuth from "contexts/auth/auth.context.hooks";
import { useFetchUser } from "services/user/user.service.hooks";
import { auth } from "config/artisn.config";

const useListenUser = () => {
  const { push, asPath } = useRouter();
  const { isAnonymous, uid, setProviderData } = useAuth();
  const { currentUser } = auth() ?? {};
  const { data: user, error } = useFetchUser();
  const { message } = error ?? {};

  // Redirect user depending on how complete is its registered process
  useEffect(() => {
    if (!isAnonymous && user) return;
    if (asPath === "/signup-user" || asPath.includes("redirect")) return;
    if (message === "No user for uid") {
      push(`/signup-user/?redirect=${asPath ?? "/"}`);
      return;
    }
  }, [message, push, isAnonymous, uid, user, asPath]);

  useEffect(() => {
    if (!currentUser) return;
    setProviderData(currentUser.providerData);
  }, [currentUser, setProviderData]);
};

export default useListenUser;
