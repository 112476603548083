// PasswordStrength helper functions and data
import { Options, passwordStrength } from "check-password-strength";
import { FieldError } from "react-hook-form";

import { Strength } from "./PasswordStrength.types";

export const defaultOptions: Options<Strength> = [
  {
    id: 0,
    value: "Too weak",
    minDiversity: 0,
    minLength: 0
  },
  {
    id: 1,
    value: "Weak",
    minDiversity: 2,
    minLength: 7
  },
  {
    id: 2,
    value: "Medium",
    minDiversity: 3,
    minLength: 8
  },
  {
    id: 3,
    value: "Strong",
    minDiversity: 4,
    minLength: 10
  }
];

export const allowedSymbols = "[<>;,:.'_@$!%*&\"¡¿?°/()=|+~{}-]";

export const isPasswordInvalid = (
  password: string | undefined,
  passwordError: FieldError | undefined
): boolean => {
  if (typeof password === "undefined" || password?.trim() === "") return true;
  const { contains } = passwordStrength<Strength>(
    password,
    defaultOptions,
    allowedSymbols
  );
  return (
    !contains.includes("uppercase") ||
    !contains.includes("number") ||
    !contains.includes("symbol") ||
    !!passwordError
  );
};
