// Form utility functions
import * as yup from "yup";
import { DocumentType } from "artisn/types";

import { allowedSymbols } from "components/global/PasswordStrength/PasswordStrength.helpers";

export const validationMessages = {
  required: "Campo requerido",
  invalidEmail: "Correo electrónico no válido",
  minLength: (min: number) => `El campo debe tener ${min} caracteres mínimo`,
  maxLength: (max: number) => `El campo debe tener ${max} caracteres máximo`,
  onlyNumbers: "El campo debe contener solo números",
  phoneBeginWith: "El número celular debe empezar por 09 y tener 10 caracteres",
  minAge: "Debes ser mayor de 18 para adoptar"
};

const { invalidEmail, required, maxLength, minLength } = validationMessages;
const { onlyNumbers, phoneBeginWith, minAge } = validationMessages;

export const validationRules = {
  requiredDocumentType: yup.string().required(required),
  email: yup
    .string()
    .email(invalidEmail)
    .min(3, minLength(3))
    .max(50, maxLength(50)),
  requiredString: yup
    .string()
    .required(required)
    .min(3, minLength(3))
    .max(50, maxLength(50))
    .nullable(),
  simpleRequiredString: yup.string().required(required).nullable(),
  requiredNumber: yup
    .number()
    .typeError(onlyNumbers)
    .required(required)
    .min(3, minLength(3))
    .max(50, maxLength(50)),
  requiredMinAge: yup
    .number()
    .typeError(onlyNumbers)
    .required(required)
    .min(18, minAge),
  requiredBoolean: yup.bool().oneOf([true], required),
  requiredArray: yup.array().required(required).nullable(),
  requiredTagsArray: yup
    .array()
    .required(required)
    .min(1, "Se requiere por lo menos 1 Tag")
    .max(2, "Se puede agregar 2 Tags máximo")
    .nullable(),
  requiredEmail: yup
    .string()
    .required(required)
    .email(invalidEmail)
    .min(3, minLength(3))
    .max(50, maxLength(50)),
  requiredPhoneNumber: yup
    .string()
    .required(required)
    .matches(/^09\d{8}$/, phoneBeginWith)
    .test(
      "len",
      "El campo debe tener 10 caracteres",
      value => value?.toString().length === 10
    ),
  customPhoneNumber: (length: number) =>
    yup
      .string()
      .required(required)
      .matches(/^[0-9]+$/, onlyNumbers)
      .test(
        "len",
        `El campo debe tener ${length} caracteres`,
        value => value?.toString().length === length
      ),
  minLength: (min: number) =>
    yup.string().required(required).min(min, minLength(min)),
  maxLength: (max: number) =>
    yup.string().required(required).max(max, maxLength(max)),
  customName: (min: number) =>
    yup
      .string()
      .required(required)
      .min(min, minLength(min))
      .max(50, maxLength(50))
      .matches(
        /^[^0-9_!¡?÷?¿/\\+=@#$%^&*(){}|~<>;:[\]]{2,}$/i,
        "El campo debe tener solo letras"
      ),
  password: yup
    .string()
    .min(8, minLength(8))
    .matches(/[A-Z]/, "Debe contener al menos una letra mayúscula")
    .matches(/[0-9]/, "Debe contener al menos un número")
    .matches(
      new RegExp(allowedSymbols),
      "Debe contener al menos un caracter especial"
    ),
  requiredBillingAddress: yup
    .string()
    .required(required)
    .min(3, minLength(3))
    .nullable(),
  twoOrMoreWords: (message?: string) =>
    yup
      .string()
      .required(required)
      .matches(/^[^\d]+$/, "El campo no debe contener números")
      .matches(
        /^[a-zA-Z\u00C0-\u1FFF]+\s+([a-zA-Z\u00C0-\u1FFF]+\s?)+/,
        message ?? "El campo debe contener mínimo dos palabras"
      ),
  documentRules: (documentType: DocumentType | undefined) => {
    switch (documentType) {
      case "CI":
        return yup
          .string()
          .required(required)
          .matches(/^\d+$/, onlyNumbers)
          .test("len", "El campo debe tener 10 caracteres", value => {
            return value?.length === 10;
          })
          .nullable();
      case "RUC":
        return yup
          .string()
          .required(required)
          .matches(/^\d+$/, onlyNumbers)
          .test(
            "len",
            "El campo debe tener 13 caracteres",
            value => value?.length === 13
          )
          .nullable();
      default:
        return yup
          .string()
          .required(required)
          .min(1, minLength(1))
          .max(20, maxLength(20))
          .matches(
            /^[0-9a-zA-Z]+$/,
            "El campo no debe contener caracteres especiales"
          )
          .nullable();
    }
  }
};

export const trimFields = <T>(obj: T): T => {
  if (!obj) return obj;
  return JSON.parse(JSON.stringify(obj).replace(/"\s+|\s+"/g, '"'));
};

export const getMaxLength = (documentType: string | undefined) => {
  switch (documentType) {
    case "CI":
      return 10;
    case "RUC":
      return 13;
    default:
      return 20;
  }
};

export const isRequired = (field: string, value: string) => {
  return yup
    .string()
    .when(field, {
      is: value,
      then: fieldSchema => fieldSchema.required(required)
    })
    .nullable();
};
