import React, { useRef } from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { NotificationsContainer } from "artisn-ui-react";
import { QueryClientProvider, QueryClient } from "react-query";
import { Hydrate } from "react-query/hydration";
// import { ReactQueryDevtools } from "react-query/devtools"

import { ProvidersProps as Props } from "./Providers.types";
import ThemeProvider from "contexts/theme/theme.context";
import ArtisnProvider from "containers/ArtisnProvider/ArtisnProvider";
import Error from "components/error/Error/Error";
import CONSTANTS from "config/constants";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";

const { GENERAL, TITLE, FEATURE_FLAGS } = CONSTANTS;
const { INCLUDED_LOGGER_ENVS } = GENERAL;
const { WITH_BUGSNAG } = FEATURE_FLAGS;

const bugsnagApiKey = process.env.NEXT_PUBLIC_ENV_BUGSNAG_ID;
const withBugsnag =
  process.env.NODE_ENV === "production" && bugsnagApiKey && WITH_BUGSNAG;

if (!(Bugsnag as any)._client && withBugsnag) {
  Bugsnag.start({
    apiKey: bugsnagApiKey,
    plugins: [new BugsnagPluginReact()],
    enabledReleaseStages: INCLUDED_LOGGER_ENVS,
    releaseStage: process.env.NODE_ENV,
    metadata: {
      client: TITLE,
      env: process.env.NODE_ENV
    }
  });
}

const ErrorBoundary = withBugsnag
  ? Bugsnag.getPlugin("react")!.createErrorBoundary(React)
  : ReactErrorBoundary;

const Providers: React.FC<Props> = props => {
  const { children, dehydratedState } = props;

  const queryClientRef = useRef<QueryClient>();
  if (!queryClientRef.current) {
    queryClientRef.current = new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: process.env.NODE_ENV === "production",
          staleTime: 15 * 1000 * 60
        }
      }
    });
  }

  return (
    <ThemeProvider>
      <ErrorBoundary FallbackComponent={Error}>
        <QueryClientProvider client={queryClientRef.current}>
          <Hydrate state={dehydratedState}>
            {/* {process.env.NODE_ENV !== "test" ? <ReactQueryDevtools /> : null} */}
            <ArtisnProvider>
              <NotificationsContainer />
              {children}
            </ArtisnProvider>
          </Hydrate>
        </QueryClientProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

Providers.defaultProps = {};

export default Providers;
