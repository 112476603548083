import React, { useEffect, useState } from "react";
import { deleteShoppingCart, mergeShoppingCart } from "artisn/shopping-cart";
import { checkInit, createShoppingCart } from "artisn/shopping-cart";
import { Storage } from "@capacitor/storage";
import { ShoppingCart } from "artisn/types";

import Styles from "./MergeCartModal.styles";
import { MergeCartModalProps as Props } from "./MergeCartModal.types";
import useAuth from "contexts/auth/auth.context.hooks";
import CONSTANTS from "config/constants";
import useShoppingCart from "contexts/shoppingCart/shoppingCart.context.hooks";
import useCatalogues from "contexts/catalogues/catalogues.hooks";
import useShippingCost from "hooks/useShippingCost";

import CartErrorSVG from "../../../../public/assets/images/cart-error.svg";

const { ARTISN, STORAGE } = CONSTANTS;
const { SHOPPING_CART_DEFAULT_NAME } = ARTISN;
const { ANONYMOUS_SHOPPING_CART_TOKEN } = STORAGE;

const MergeCartModal: React.FC<Props> = props => {
  const { isAnonymous } = useAuth();
  const [opened, setOpened] = useState(false);
  const [anonymousCart, setAnonymousCart] = useState<ShoppingCart>();
  const { shoppingCart, isAnonymousCart } = useShoppingCart();
  const { name: shoppingCartName = SHOPPING_CART_DEFAULT_NAME } =
    shoppingCart ?? {};
  const { catalogueId } = useCatalogues()?.selectedCatalogue ?? {};
  const shippingCost = useShippingCost();

  const mergeAnonymousShoppingCart = async () => {
    if (!anonymousCart) return;
    await mergeShoppingCart(anonymousCart, {
      shoppingCartName: SHOPPING_CART_DEFAULT_NAME
    });
    await Storage.remove({
      key: ANONYMOUS_SHOPPING_CART_TOKEN
    });
    closeModal();
  };

  const replaceShoppingCart = async () => {
    if (!anonymousCart) return;
    await Storage.remove({
      key: ANONYMOUS_SHOPPING_CART_TOKEN
    });
    if (shoppingCartName) {
      await deleteShoppingCart({ shoppingCartName, anonymous: isAnonymous });
    }
    await createShoppingCart(
      {
        anonymous: isAnonymous
      },
      {
        stores: anonymousCart.stores,
        channelId: +catalogueId,
        shippingCost,
        name: shoppingCartName
      }
    );
    closeModal();
  };

  const closeModal = () => {
    setOpened(false);
  };

  const closeModalHandler = async () => {
    await Storage.remove({
      key: ANONYMOUS_SHOPPING_CART_TOKEN
    });
    closeModal();
  };

  useEffect(() => {
    (async () => {
      if (isAnonymous || !shoppingCart) {
        setOpened(false);
        return;
      }
      const { value: incomingCart } = await Storage.get({
        key: ANONYMOUS_SHOPPING_CART_TOKEN
      });
      if (!incomingCart) return;
      const anonymousShoppingCart = JSON.parse(incomingCart);
      setAnonymousCart(anonymousShoppingCart);
      setOpened(true);
    })();
  }, [isAnonymous, shoppingCart]);

  return (
    <Styles
      className="MergeCartModal"
      title="¿Deseas reemplazar tu carrito anterior?"
      description="Ya habías guardado productos anteriormente en tu carrito, ¿Deseas mantener los productos?"
      cancelText="Mantener ambos"
      confirmText="Crear nuevo carrito"
      confirmAction={replaceShoppingCart}
      cancelAction={mergeAnonymousShoppingCart}
      opened={checkInit() && opened && !isAnonymousCart}
      icon={<CartErrorSVG />}
      onClose={closeModalHandler}
    />
  );
};

MergeCartModal.defaultProps = {};

export default MergeCartModal;
