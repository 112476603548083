// Stores services
import { Catalogue, Store } from "artisn/types";

import axiosDefault from "utils/axios.utils";
import CONSTANTS, { DELIVERY } from "config/constants";
import { shouldMock } from "utils/common.utils";

const { ACCOUNT_ID } = CONSTANTS.ARTISN;
const baseUrl = "api/v3/stores";

/**
 * Fetches a list of the closest stores from the given coordinates from the
 * closest to the furthest.
 *
 * @param {number} lat Latitude
 * @param {number} lng Longitude
 * @param {string} catalogueId Used to filter stores by catalogue
 * @returns {Store[]} The closest stores to a given coordinate
 */
export const fetchNearbyStores = async (
  lat: number,
  lng: number,
  catalogueId: Catalogue["catalogueId"]
): Promise<Store[]> => {
  try {
    if (!shouldMock) {
      const isDelivery = catalogueId === DELIVERY.catalogueId;
      const { data } = await axiosDefault.get(baseUrl, {
        params: {
          accountId: ACCOUNT_ID,
          catalogueId,
          geoType: isDelivery ? "geo-shape" : "geo-distance",
          lat,
          lng
        }
      });

      return data.data;
    } else {
      const { mockStores } = await import("./stores.service.mock");
      return await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(mockStores(catalogueId));
        }, 1000);
      });
    }
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Fetches all stores.
 *
 * @param {string} catalogueId Used to filter stores by catalogue
 * @returns {Store[]} The list of stores
 */
export const fetchStores = async (
  catalogueId: Catalogue["catalogueId"],
  query?: string
): Promise<Store[]> => {
  try {
    if (!shouldMock) {
      const isDelivery = catalogueId === DELIVERY.catalogueId;
      const { data } = await axiosDefault.get(baseUrl, {
        params: {
          accountId: ACCOUNT_ID,
          catalogueId,
          geoType: isDelivery ? "geo-shape" : "geo-distance",
          query,
          additionalFields: "polygons"
        }
      });

      return data.data;
    } else {
      const { mockStores } = await import("./stores.service.mock");
      return await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(mockStores(catalogueId));
        }, 1000);
      });
    }
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Fetches the default store.
 *
 * @param {string} catalogueId Used to filter stores by catalogue
 * @returns {Store} The default store
 */
export const fetchDefaultStore = async (
  catalogueId: Catalogue["catalogueId"]
): Promise<Store> => {
  try {
    if (!shouldMock) {
      const { data } = await axiosDefault.get(baseUrl, {
        params: {
          accountId: ACCOUNT_ID,
          catalogueId,
          geoType: "geo-distance",
          isDefault: true
        }
      });

      const [defaultStore] = data.data;

      if (!defaultStore) {
        throw new Error("No default store data");
      }

      return defaultStore;
    } else {
      const { mockStore } = await import("./stores.service.mock");
      return await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(mockStore({ storeId: 1, isDefault: true }));
        }, 1000);
      });
    }
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Fetches the details of a given store.
 *
 * @param {number} storeId The id of the store that will be retrieved
 * @returns {Store} A store that matches the given storeId
 */
export const fetchStoreDetails = async (storeId: number): Promise<Store> => {
  try {
    if (!shouldMock) {
      const { data } = await axiosDefault.get(baseUrl, {
        params: {
          accountId: ACCOUNT_ID,
          storeId
        }
      });
      return data.data[0];
    } else {
      const { mockStore } = await import("./stores.service.mock");
      return await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(mockStore({ storeId }));
        }, 1000);
      });
    }
  } catch (e) {
    throw new Error(e.message);
  }
};
