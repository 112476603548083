import { opacify } from "polished";
import { css } from "styled-components";

import variables from "styles/util/variables";
import { getMaxHeight } from "utils/styling.utils";
import CONSTANTS from "config/constants";

const { mobile, tablet } = CONSTANTS.BREAKPOINTS;
const primary = variables.palette["purple-s85-l45"];

const base = css`
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
  }

  html {
    font-size: 62.5%; //16px default to 10px
  }

  body {
    box-sizing: border-box;
    background-color: var(--palette-gray-s0-l98);
    max-width: 100%;
    margin-top: env(safe-area-inset-top);
    margin-bottom: env(safe-area-inset-bottom);
    margin-left: env(safe-area-inset-left);
    margin-right: env(safe-area-inset-right);
  }

  :is(.TabsMenu__page, .Layout) > * {
    grid-template-rows: max-content 1fr max-content;
  }

  h2 {
    color: var(--palette-blue-s51-l16);
  }

  p {
    color: var(--palette-blue-s51-l16);
    font-size: 1.6rem;
  }

  select {
    appearance: none;
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    outline: none;
    z-index: 1;

    &::-ms-expand {
      display: none;
    }
  }

  .SettingsDrawer {
    &__separator {
      height: 0.8rem;
      background-color: var(--palette-gray-s0-l98);
      margin-left: -2.4rem;
      margin-right: -2.4rem;
    }

    &__info-card {
      padding: 1.6rem 0;
    }

    &__drawer {
      &__header {
        position: relative;
        padding-bottom: 4rem;

        &__title {
          text-align: center;
          font-family: Inter;
          font-size: 1.6rem;
          line-height: 2.4rem;
        }

        &__cross {
          position: absolute;
          top: 0;
          right: 0;
          cursor: pointer;
        }
      }

      &__buttonGroupText {
        font-family: Inter;
        font-size: 1.2rem;
        line-height: 2rem;
        color: var(--palette-gray-s0-l40);
      }

      &__button {
        margin-top: auto;
        display: flex;
        justify-content: center;
        padding: 1rem 1.6rem;
        background: var(--palette-black-s0-l10);
        border-radius: 0.4rem;
        border: none;
        cursor: pointer;

        &__text {
          font-family: Inter;
          font-size: 1.6rem;
          line-height: 2.4rem;
          color: var(--palette-white);
        }
      }
    }

    &__buttonGroup {
      padding-top: 0.8rem;
      padding-bottom: 2.4rem;

      .Button--active {
        border: 0.1rem solid var(--palette-primary) !important;
        color: var(--palette-primary);
      }

      &__button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1.6rem 2.4rem;
        border: 0.1rem solid var(--palette-gray-s0-l80);
        width: 11.2rem;
        height: 4.8rem;
        font-size: 1.6rem;

        &:first-child {
          border-top-left-radius: 0.4rem;
          border-bottom-left-radius: 0.4rem;
        }

        &:last-child {
          border-top-right-radius: 0.4rem;
          border-bottom-right-radius: 0.4rem;
        }
      }
    }
  }

  .SearchBar__item--active {
    background-color: var(--palette-gray-s0-l98);
  }

  .CheckoutOrderStatus {
    &__modal {
      width: 100%;
      height: ${getMaxHeight};
    }
  }

  .Modal {
    border-radius: 0 !important;

    @media (max-width: ${tablet}px) {
      border-radius: 3.6rem !important;
      overflow: hidden !important;

      &__body {
        overflow: hidden !important;
      }
    }
  }

  .CheckoutExitModal {
    &__modal {
      border-radius: 2.4rem !important;

      @media (max-width: ${tablet}px) {
        border-radius: unset !important;
        width: 100%;
      }
    }
  }

  .TermsAndConditionsModal {
    &__modal {
      border-radius: 2.4rem !important;

      @media (max-width: ${tablet}px) {
        border-radius: unset !important;
        width: 100%;
      }

      .Modal__body {
        @media (max-width: ${tablet}px) {
          overflow-y: scroll !important;
        }
      }
    }
  }

  .CheckoutResetValuesModal {
    &__modal {
      border-radius: 2.4rem !important;

      @media (max-width: ${tablet}px) {
        width: 100%;
      }
    }
  }

  .CheckoutModal {
    &__modal {
      border-radius: 2.4rem !important;
      overflow: hidden;

      @media (max-width: ${tablet}px) {
        width: 100%;
      }
    }
  }

  .CropModal {
    border-radius: 2.4rem !important;

    @media (max-width: ${tablet}px) {
      border-radius: 0 !important;
    }
  }

  .SearchBar__wrapper,
  .SearchBar__btn {
    border-radius: 0.8rem;
  }

  .SearchBar__wrapper > .SearchBar__btn.SearchBar__btn-search {
    padding-left: 1.2rem;
    width: 3.6rem;
  }

  .Category,
  .Products {
    &__drawer {
      width: 100%;
      height: 100%;
      padding-top: 1.6rem;

      &__close {
        position: absolute;
        top: 2.4rem;
        right: 2rem;
        z-index: 2;
        cursor: pointer;
      }
    }

    &__results {
      position: absolute;
      width: 100%;
      bottom: 0;
      padding: 1.6rem 2.4rem;
      background-color: var(--palette-white);
      z-index: 2;

      &&& &__button {
        font-family: inherit;
        width: 100%;
        justify-content: center;
        padding: 1.8rem 2.4rem;
      }
    }
  }

  .field {
    padding: 1.2rem 0;

    &&& label {
      color: var(--palette-black-s0-l10);
      padding-bottom: 0.6rem;
      font-size: 1.4rem;

      &.Checkbox__label {
        padding-bottom: 0;
      }
    }

    &&& input {
      padding: 1.2rem;
      font-size: 1.6rem;
      color: var(--palette-black-s0-l10);
      background-color: var(--palette-white);
      border: 0.1rem solid var(--palette-gray-s0-l95);
      font-weight: 600;
      border-radius: 1rem;
      outline: none;
      line-height: 2.4rem;

      @media (max-width: ${mobile}px) {
        border: 0.1rem solid var(--palette-gray-s0-l90);
      }

      &:hover {
        z-index: 1;
        border-color: var(--palette-primary);
      }

      &::placeholder {
        color: var(--palette-gray-s0-l80);
      }

      &:focus {
        z-index: 1;
        border-color: var(--palette-primary);
        box-shadow: 0 0 0 0.1rem ${opacify(-0.8, primary)};
      }

      &.PhoneNumberInput__input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    &&& figure {
      z-index: 1;
    }

    &&& .SelectCountry {
      background-color: var(--palette-gray-s0-l98);

      &:hover {
        border-color: var(--palette-primary);

        & > * {
          border-color: var(--palette-primary);
        }
      }

      &__flag {
        border-color: var(--palette-gray-s0-l95);
      }

      &__select {
        border-color: var(--palette-gray-s0-l95);
      }
    }

    &&& .DocumentTypeSelect__selectContainer {
      background-color: var(--palette-gray-s0-l98);
      border-color: var(--palette-gray-s0-l95);

      &:hover {
        border-color: var(--palette-primary);
      }

      &:focus-within {
        border-color: var(--palette-primary);
        box-shadow: 0 0 0 0.1rem ${opacify(-0.8, primary)};
      }
    }
  }

  .errorMessage {
    display: inline-block;
    padding-top: 0.4rem;
    font-size: 1.2rem;
    color: var(--palette-red-s79-l63);
  }

  .radio {
    display: flex;
    align-items: center;
    padding-top: 1.6rem;
    flex-wrap: wrap;

    div {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      margin-right: 1.6rem;

      @media (max-width: ${tablet}px) {
        margin-bottom: 0.8rem;
      }

      input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
        border: 0.1rem solid var(--palette-gray-s0-l70-a48);
        transition: 0.2s all linear;
        margin-right: 0.5rem;
        position: relative;

        &:checked {
          border: 0.6rem solid var(--palette-red-s85-l45);
        }
      }

      label {
        font-size: 1.6rem;
        padding-left: 0.4rem;
        user-select: none;
      }
    }
  }

  .checkbox {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    div {
      display: flex;
      align-items: center;
      margin-right: 1.6rem;
      padding-top: 1.6rem;

      input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-radius: 0.2rem;
        width: 2rem;
        height: 2rem;
        border: 0.1rem solid var(--palette-gray-s0-l70-a48);
        transition: 0.2s all linear;
        margin-right: 0.4rem;
        position: relative;

        &:checked:before {
          content: "";
          display: block;
          width: 2rem;
          height: 2rem;
          background-color: var(--palette-red-s85-l45);
          border-radius: 0.2rem;
        }

        &:checked:after {
          content: "";
          display: block;
          width: 0.5rem;
          height: 1rem;
          border: solid var(--palette-white);
          border-width: 0 0.1rem 0.1rem 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
          position: absolute;
          top: 0.4rem;
          left: 0.72rem;
        }
      }

      label {
        font-size: 1.6rem;
        padding-left: 0.4rem;
        user-select: none;
        height: 100%;
      }
    }
  }

  .border__item {
    border: 0.1rem solid var(--palette-black-s0-l10) !important;
    display: grid !important;
    grid-template-columns: max-content 1fr max-content;
    align-items: center !important;
    padding: 1.2rem 1.6rem !important;
    min-height: 6.4rem !important;
    border-radius: 0.4rem !important;

    &--inactive {
      border: 0.1rem solid var(--palette-gray-s0-l95) !important;
    }
  }

  .loading-shine {
    background-color: #f0f0f0;
    position: relative;
    overflow: hidden;

    &::before {
      content: "";
      display: block;
      background: linear-gradient(
        to right,
        transparent,
        rgba(180, 180, 180, 0.3),
        transparent
      );
      animation: load 1.5s cubic-bezier(0.4, 0, 0.3, 1) infinite;
      position: absolute;
      left: -100%;
      top: 0;
      height: 100%;
      width: 100%;
    }
  }

  div.SettingsDrawer__drawer {
    padding: 1.6rem 2.4rem;
    padding-top: calc(1.6rem + env(safe-area-inset-top));
    min-width: 40rem;
    min-height: var(--sizes-page-maxHeight);
    max-height: calc(var(--sizes-page-maxHeight) - 5.7rem);

    @media (hover: none) {
      width: var(--sizes-page-maxWidth);
      min-height: calc(var(--sizes-page-maxHeight) - 5.7rem);
    }
  }

  .SettingsDrawer {
    &__buttonGroup {
      &__button {
        flex: 1;
      }
    }
  }

  &&& .NotificationsContainer {
    & &__container {
      &--top-center {
        top: 0;
        left: 0;
        transform: none;
        width: 100%;
        height: max-content;

        .NotificationsContainer__item {
          width: 100%;

          .Notification {
            min-height: 6rem;
            height: max-content;
            width: 100%;
            max-width: none;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: none;
            border-radius: 0;
          }
        }
      }
    }
  }

  .UpdateAppModal,
  .OutOfServiceModal {
    &__backdrop {
      height: 100vh !important;
    }
  }

  .SearchBarContent,
  .DropdownOverlay,
  .ProductMainButtonsOverlay {
    position: fixed;
    background-color: var(--palette-white);
    border-radius: 2.4rem;
    overflow: hidden;
    margin-top: 1.6rem;
    box-shadow: 0rem 0.4rem 1.6rem 0rem #3333330f;

    @media (max-width: ${tablet}px) {
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .DropdownOverlayMenu,
  .DropdownOverlayCities {
    @media (max-width: ${tablet}px) {
      height: 100vh;
      border-radius: 0;
      background-color: var(--palette-gray-s0-l40-a40);
      opacity: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }

  &&&.ProductDetailsImage {
    &__modal {
      width: auto !important;
      height: auto !important;
      border-radius: 2rem !important;
      background-color: transparent;

      @media (max-width: ${tablet}px) {
        width: calc(
          100vw - (100vw - 100%) - env(safe-area-inset-left) -
            env(safe-area-inset-right)
        ) !important;
        height: 90vh !important;
        border-radius: unset !important;
      }

      @media (max-width: ${mobile}px) {
        height: 70vh !important;
      }

      &__image {
        width: 100% !important;
        height: 100% !important;

        .Image__img {
          width: 100%;
          height: 100%;

          @media (max-width: ${mobile}px) {
            border-radius: unset !important;
          }
        }
      }

      &__carousel {
        background-color: var(--palette-cream-s42-l93);
        padding: 1rem 0;
        border-radius: 1.6rem;
        position: absolute;
        bottom: 0;
        margin-bottom: 2rem;
        width: calc(100% - 4rem);
        left: 50%;
        transform: translateX(-50%);
        justify-content: space-between;

        .ThumbnailCarousel__action {
          width: 3.2rem;
          height: 3.2rem;
          padding: 0;
          z-index: 4;

          svg {
            pointer-events: none;

            path {
              fill: var(--palette-white);
            }
          }
        }

        .ThumbnailCarousel__slider {
          flex: 1;
        }

        .ThumbnailCarousel__slider-item {
          .Image__img {
            opacity: 0.5;

            &--active {
              opacity: 1;
              border: 0.2rem solid var(--palette-primary-hex);
            }
          }
        }
      }

      && .Image__img {
        object-fit: cover;
        border-radius: 1.2rem;
      }
    }
  }

  .ThumbnailCarousel__slider {
    filter: drop-shadow(0rem 0.4rem 0.4rem rgba(51, 51, 51, 0.04))
      drop-shadow(0rem 0.4rem 2.4rem rgba(51, 51, 51, 0.24));
    width: 50rem;
  }

  .ThumbnailCarousel__action {
    background-color: var(--palette-gray-s0-l70-a48);
    border-radius: 50%;
    margin: 1.6rem;
  }

  .Image__img {
    object-fit: cover;
  }

  .Button__wrapper {
    font-weight: 800;
  }

  .AddShelterExitModal {
    border-radius: 3.6rem !important;
  }
`;

export default base;
