import React from "react";
import router from "next/router";

import Styles from "./AddToWishlistNotification.styles";
import { AddToWishlistNotificationProps as Props } from "./AddToWishlistNotification.types";
import Button from "components/global/Button/Button";

const AddToWishlistNotification: React.FC<Props> = props => {
  const { className, isAnonymous } = props;
  const description = isAnonymous
    ? "Para agregar a favoritos necesitas tener una cuenta"
    : "Se ha añadido a tu lista de favoritos";

  const clickHandler = () => {
    if (isAnonymous) {
      router.push("/signin");
      return;
    }
    router.push("/profile/wishlist");
  };

  return (
    <Styles
      className={`AddToWishlistNotification ${className}`}
      showCloseIcon={false}
      isAnonymous={isAnonymous}
    >
      <p className="AddToWishlistNotification__description">{description}</p>
      <Button
        className="AddToWishlistNotification__button"
        type="LINK"
        onClick={clickHandler}
      >
        {isAnonymous ? "Ingresar" : "Ver favoritos"}
      </Button>
    </Styles>
  );
};

AddToWishlistNotification.defaultProps = {};

export default AddToWishlistNotification;
