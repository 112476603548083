import styled from "styled-components";

import { ErrorStyledProps as Props } from "./Error.types";

const ErrorStyled = styled.div<Props>`
  min-width: var(--sizes-page-minWidth);
  max-width: var(--sizes-page-maxWidth);
  min-height: var(--sizes-page-minHeight);
  display: flex;
  flex-direction: column;
  background-color: var(--palette-cream-s42-l93);

  .Error {
    &__header {
    }

    &__main {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 2rem;
      text-align: center;
    }

    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 3.8rem;
    }

    &__info {
      color: var(--palette-blue-s51-l16);
      font-size: 2.4rem;
      line-height: 2.8rem;
      font-weight: 600;
    }

    &__description {
      color: var(--palette-blue-s51-l16);
      font-size: 2rem;
      line-height: 2.2rem;
      padding-top: 1rem;
    }

    &__cta {
      margin-top: 4rem;
      border-radius: 10rem !important;
      min-width: 18rem;
      min-height: 5rem;
      font-size: 1.6rem;
    }

    &__home {
      border-radius: 10rem !important;
      min-width: 18rem;
      min-height: 5rem;
      font-size: 1.6rem;
      margin-top: 2rem;
    }
  }
`;

export default ErrorStyled;
